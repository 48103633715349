<template>  
    <v-container>      
    <!-- Para que un ref tome efecto el nodo debe existir en el DOM (tambien se toma si esta oculto), 
    en este caso los componentes dentro de Dialog no existen hasta que la accion userData.dialog 
    es true -->
      <v-card elevation="0" class="d-flex mb-2 justify-center align-center" style="border-radius: 24px 24px 8px 8px;;">
        <v-card-title 
          class="pa-4 px-6 grey--text text--darken-3 font-weight-bold text-uppercase" 
          :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
        >
          Acciones Generales
        </v-card-title>
      </v-card>

    <v-row>
      <v-col v-if="IS_ADMIN" cols="12" sm="6" md="6" lg="6">
        <v-card class="pa-6 pt-0" elevation="1">
          <p class="v-card__subtitle headline ml-n4 ma-0" style="font-size: 18px !important;">
            Acciones para los planes con forma de pago <b>Diario</b>
            con respecto a la siguiente fecha seleccionada:
          </p>
          <v-divider class="pb-6"></v-divider>

            <!-- <p class="subtitle-1">
              
            </p> -->
            <v-menu
              ref="dateRangeMenuAbono0"
              v-model="filter_datePagoRange.dateOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <VTextField
                  :value="filter_datePagoRange.formattedDate"
                  :label="`Fecha`"
                  :hint="
                    filter_datePagoRange.dateText
                      ? `${$dayjs(filter_datePagoRange.dateText).format('dddd, DD [de] MMMM [del] YYYY')}`
                      : filter_datePagoRange.dateText
                  "
                  class="mt-0"
                  prepend-inner-icon="mdi-calendar"
                  persistent-hint
                  readonly
                  v-on="on"
                ></VTextField>
              </template>
              <v-date-picker
                v-model="filter_datePagoRange.dateText"
                @change="filter_datePagoRange.dateOpen = false"
                no-title
                scrollable>
                <!-- <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.dateRangeMenuAbono0.save(handleDatePagoFilterRange(filter_datePagoRange.dateText))">OK</v-btn> -->
              </v-date-picker>
            </v-menu>
            <div class="mt-4" style="margin: auto;">
              <br />

              <Button
                color="primary"
                :disabled="!filter_datePagoRange.dateText"
                :loading="loading"
                @click="dialogAbono0Model = true"
              >
                Aplicar abonos en $0.00
              </Button>
            </div>

            <br />

            <div class="mt-4" style="margin: auto;">
              <Button
                text
                elevation="1"
                color="primary"
                :disabled="!filter_datePagoRange.dateText"
                :loading="loading"
                @click="dialogCancelarAbono0Model = true"
              >
                Cancelar abonos en $0.00
              </Button>
            </div>

            <br />
          
        </v-card>
      </v-col>

      <v-col v-if="IS_DEV_USER" cols="12" sm="6" md="6" lg="6">
        <v-card class="pa-6 pt-0" elevation="1">
          <v-card-title class="v-card__subtitle headline ml-n4" style="font-size: 18px !important;">
            Acciones (dev)
          </v-card-title>
          <v-divider class="pb-6"></v-divider>
    
          <div style="margin: auto;">
            <Button 
              text 
              color="primary"
              :loading="loading"
              @click="actSaldosEnContra"
            >
              Act. saldos en contra
            </Button>
          </div>
          
        </v-card>
      </v-col>
    </v-row>

    <Dialog
      v-model="dialogAbono0Model"
      :title="`Aplicación abonos en 0`"
      useToolbarStyle
      @onCloseToolbarBtn="dialogAbono0Model = false"
    >
      <template slot="content">
        <v-card-title class="justify-center">
          <span class="headline" style="word-break: initial;">
            ¿Seguro de que desea <b>APLICAR</b> los abonos en 0 para los planes con forma de pago <b>Diario</b> {{ filter_datePagoRange.dateText == $dayjs().format('YYYY-MM-DD') ? 'el día de HOY' : 'en la fecha seleccionada' }} (<b>{{ `${$dayjs(filter_datePagoRange.dateText).format('dddd, DD [de] MMMM [del] YYYY')}` }}</b>)?
          </span>
        </v-card-title>
      </template>

      <template slot="actions">
        <Button text @click="dialogAbono0Model = false">Cancelar</Button>
        <Button text @click="abonarPagosDiariosEnCero">Aceptar</Button>
      </template>
    </Dialog>

    <Dialog
      v-model="dialogCancelarAbono0Model"
      :title="`Cancelar abonos en 0`"
      useToolbarStyle
      @onCloseToolbarBtn="dialogCancelarAbono0Model = false"
    >
      <template slot="content">
        <v-card-title class="justify-center">
          <span class="headline" style="word-break: initial;">
            ¿Seguro de que desea <b>CANCELAR</b> los abonos en 0 para los planes con forma de pago <b>Diario</b> {{ filter_datePagoRange.dateText == $dayjs().format('YYYY-MM-DD') ? 'el día de HOY' : 'en la fecha seleccionada' }} (<b>{{ `${$dayjs(filter_datePagoRange.dateText).format('dddd, DD [de] MMMM [del] YYYY')}` }}</b>)?
          </span>
        </v-card-title>
      </template>

      <template slot="actions">
        <Button text @click="dialogCancelarAbono0Model = false">Cancelar</Button>
        <Button text @click="cancelarPagosDiariosEnCero">Aceptar</Button>
      </template>
    </Dialog>

  </v-container>
</template>

<script>
import { ref } from "vue";
import Dialog from "@/components/ui/Dialog";
import Button from "@/components/ui/Button";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";
import useDatepicker from "@/composables/useDatepicker";

export default {
  components: {
    Dialog,
    Button,
  },
  setup(props, ctx) {
    const { APP, USERS, DEV_ADMIN } = STORE_NAMESPACES;

    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const IS_ADMIN = getters[APP].getIsAdmin.value;

    const dataSource = getters[USERS].findById(state[APP].idUser);
    const filter_datePagoRange = useDatepicker(0, "", false);
    const dialogAbono0Model = ref(false);
    const dialogCancelarAbono0Model = ref(false);

    const loading = getters[APP].getLoading;

    function actSaldosEnContra() {
      actions[DEV_ADMIN].UPDATE_SALDOS_EN_CONTRA();
    }

    function abonarPagosDiariosEnCero() {
      const user = dataSource.value;
      actions[DEV_ADMIN].INSERT_MULTIPLES_ABONOS_EN_CERO_PAGO_DIARIO(filter_datePagoRange.dateText, user.id, () => {
        dialogAbono0Model.value = false;
      });
    }
    
    function cancelarPagosDiariosEnCero() {
      const user = dataSource.value;
      actions[DEV_ADMIN].CANCELAR_MULTIPLES_ABONOS_EN_CERO_PAGO_DIARIO(filter_datePagoRange.dateText, user.id, () => {
        dialogCancelarAbono0Model.value = false;
      });
    }

    return {
      actSaldosEnContra,
      abonarPagosDiariosEnCero,
      cancelarPagosDiariosEnCero,
      dialogAbono0Model,
      dialogCancelarAbono0Model,
      filter_datePagoRange,

      loading,

      IS_ADMIN,
      IS_DEV_USER,
    };
  }
};
</script>

